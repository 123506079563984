<template>
    <button v-show="goToTopButtonVisible"
            @click="goToTop"
            class="btn btn-primary" id="go-to-top-btn">
        <i class="bi bi-arrow-up-circle"></i>
    </button>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
    name: "GoToTopButton",
    setup() {
        const goToTopButtonVisible = ref(false);

        const goToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }

        onMounted(() => {
            window.onscroll = () => goToTopButtonVisible.value = window.scrollY > 200;
        });

        return {
            goToTopButtonVisible,
            goToTop
        }

    }
}
</script>

<style scoped>

#go-to-top-btn {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 10;
}

</style>