<template>
    <div class="card p-2 mb-3 bg-light">
        <div class="row">
            <div class="col-6">
                <div class="input-group">
                    <input :value="key" readonly class="form-control">
                    <div class="input-group-append">
                        <button @click="copy" class="btn btn-outline-secondary" type="button">
                            <i class="bi bi-clipboard"></i>
                        </button>
                    </div>
                </div>
                <small class="text-info" v-if="haveStartSpace || haveEndSpace">
                     {{ haveStartSpace ? 'Есть пробел в начале ' : '' }} {{ haveEndSpace ? 'Есть пробел в конце ' : '' }}
                </small>
            </div>
            <div class="col-6">
                <input @change="onChange" :value="item" class="form-control" :class="{ 'is-invalid' : isInvalidSpaces }">
                <small class="text-danger" v-if="isInvalidSpaces">
                    Проверь пробелы в начале и в конце фразы!
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast, POSITION} from "vue-toastification";
import {copyText} from 'vue3-clipboard'
import {computed} from "vue";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
    name: "Line",
    props: {
        item: Array,
    },
    emits: ["change"],
    setup(props, context) {
        const toast = useToast();

        const isInvalidSpaces = computed(() => {
            return (props.item[1] && props.item[0][0] === " " && props.item[1][0] !== " ")
                || (props.item[1] && props.item[0].slice(-1) === " " && props.item[1].slice(-1) !== " ");
        })

        const haveStartSpace = computed(() => props.item[0][0] === " ");

        const haveEndSpace = computed(() => props.item[0].slice(-1) === " ");

        const onChange = (e) => {
            context.emit("change", {
                key: props.item[0],
                value: e.target.value
            });
        }

        const copy = () => {
            copyText(props.item[0], undefined, error => {
                if (!error) {
                    toast.success('Скопировано в буффер', {
                        position: POSITION.BOTTOM_CENTER,
                        hideProgressBar: true,
                        timeout: 1000,
                        closeButton: false
                    });
                }
            });
        }

        return {
            haveStartSpace,
            haveEndSpace,
            isInvalidSpaces,
            key: props.item[0],
            item: props.item[1],
            onChange,
            copy
        }
    }
}
</script>

<style scoped>

</style>