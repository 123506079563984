<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-3 col-lg-2">
                <ul class="list-group pt-4">
                    <li v-for="(item, index) in data.projects"
                        :class="{ 'active' : index === data.currentProjectIndex }"
                        @click="data.currentProjectIndex = index"
                        :key="index" class="list-group-item p-2" style="cursor: pointer;">
                        <div>{{ item.name }}</div>
                        <small :class="{ 'text-muted' : index !== data.currentProjectIndex }">
                            {{ item.date }}
                        </small>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-9 col-lg-10">
                <div v-if="countStrings" class="alert alert-primary p-2 my-4">
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            <div class="text-muted">
                                Строк: <strong>{{ countStrings }}</strong> &#160;&#160;&#160;&#160;
                            </div>
                            <div class="pl-4 text-muted">
                                Без перевода: <strong>{{ notTranslatedStrings.length }}</strong> &#160;&#160;&#160;&#160;
                            </div>
                            <div class="d-inline-flex align-items-center pl-4">
                                <span class="text-muted">Скрыть переведенные:</span>
                                <div class="ml-2"
                                     @click="data.hideTranslated = !data.hideTranslated"
                                     style="cursor: pointer">
                                    <i v-if="!data.hideTranslated" class="bi bi-toggle-on text-secondary"
                                       style="font-size: 2rem; line-height: 1rem; display: inline-block; transform: scale(-1, 1);"></i>
                                    <i v-else class="bi bi-toggle-on text-success"
                                       style="font-size: 2rem; line-height: 1rem;"></i>
                                </div>
                            </div>
                        </div>
                        <div v-if="data.projects.length" class="col-6 text-right">
                            <button @click="exportTranslate" class="btn btn-sm btn-primary">
                                <i class="bi bi-download"></i>
                                Скачать JSON
                            </button>
                            <button @click="exportTranslate('javascript')" class="btn btn-sm btn-primary ml-2">
                                <i class="bi bi-download"></i>
                                Скачать JS
                            </button>
                            <button @click="removeTranslate" class="btn btn-sm btn-danger ml-2">
                                <i class="bi bi-trash"></i>
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
                <Line v-for="item in strings"
                      @change="setLine"
                      :key="Math.random()"
                      :item="item"/>
                <GoToTopButton/>
            </div>
        </div>
    </div>
</template>

<script>

import {computed, onMounted, reactive} from "vue";
import Line from "@/components/Line";
import GoToTopButton from "@/components/GoToTopButton";
import { saveAs } from 'file-saver';

import StoreClass from "@/classes/Store";
import LocalStorageDriver from "@/classes/LocalStorageDriver";

const Store = new StoreClass(new LocalStorageDriver());

export default {
    components: {
        Line,
        GoToTopButton
    },
    setup() {
        const data = reactive({
            projects: [],
            currentProjectIndex: 0,
            hideTranslated: false,
        });

        const currentProject = computed(() => data.projects[data.currentProjectIndex]);

        const countStrings = computed(() => Object.entries(currentProject?.value?.data || {}).length);

        const strings = computed(() => {
            return Object.entries(currentProject?.value?.data || {}).filter(item => {
                if (!data.hideTranslated) return true;
                return !item[1].length
            });
        });

        const notTranslatedStrings = computed(() => strings.value.filter(item => !item[1].length));

        const removeTranslate = async () => {
            if (!confirm("Точно?")) return;

            await Store.remove(data.currentProjectIndex)

            data.projects = await Store.all()
            data.currentProjectIndex = 0;
        }

        const exportTranslate = (type = "json") => {
            if (type === "javascript") {
              let blob = new Blob(["module.exports = " + JSON.stringify(currentProject.value.data, null, 4)], {type: "text/javascript;charset=utf-8"});
              saveAs(blob, "translate.js");
            } else {
              let blob = new Blob([JSON.stringify(currentProject.value.data, null, 4)], {type: "application/json;charset=utf-8"});
              saveAs(blob, "translate.json");
            }
        }

        const setLine = async ({key, value}) => {
            data.projects[data.currentProjectIndex].data[key] = value;
            await Store.put(data.currentProjectIndex, data.projects[data.currentProjectIndex]);
        }

        onMounted(async () => {
            data.projects = await Store.all();
        })

        return {
            data,
            currentProject,
            countStrings,
            strings,
            notTranslatedStrings,
            exportTranslate,
            removeTranslate,
            setLine
        }
    }
}
</script>

<style lang="less">

</style>
