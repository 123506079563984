export default class Store {

    constructor(driver) {
        this.driver = driver;
    }

    async all() {
        return await this.driver.all();
    }

    async get(id) {
        return await this.driver.get(id);
    }

    async push(content) {
        return await this.driver.push(content);
    }

    async put(id, content) {
        return await this.driver.put(id, content);
    }

    async remove(id) {
        return await this.driver.remove(id);
    }
}