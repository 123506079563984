export default class LocalStorageDriver {

    constructor(key = "projects") {
        this.key = key;
    }

    all() {
        return JSON.parse(localStorage.getItem(this.key) || "[]").reverse();
    }

    get(index) {
        return this.all()[index];
    }

    push(content) {
        let items = this.all();
        items.push(content);
        localStorage.setItem(this.key, JSON.stringify(items));
        return items.length;
    }

    put(index, content) {
        let items = this.all();
        items[index] = content;
        localStorage.setItem(this.key, JSON.stringify(items));
    }

    remove(index) {
        let items = this.all();
        items = items.filter((i, idx) => index !== idx);
        localStorage.setItem(this.key, JSON.stringify(items));
    }
}