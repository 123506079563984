<template>
    <nav class="navbar navbar-dark bg-dark navbar-expand-lg">
        <img src="/favicon.png" class="mr-2" style="width: 35px; height: 35px;">
        <a class="navbar-brand" href="#">
            Translator
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav mr-auto mt-2 ml-lg-2 mt-lg-0">
                <li class="nav-item" :class="{ 'active' : $route.name === 'Home' }">
                    <a @click.prevent="$router.push({name: 'Home'})" class="nav-link" href="#">
                        <i class="bi bi-house"></i>
                        Главная
                    </a>
                </li>
                <li class="nav-item" :class="{ 'active' : $route.name === 'ImportFile' }">
                    <a @click.prevent="$router.push({name: 'ImportFile'})" class="nav-link" href="#">
                        <i class="bi bi-upload"></i>
                        Импортировать файл
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Navbar",
}
</script>

<style scoped>

</style>