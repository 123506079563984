<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6 p-4 mx-auto">
                <div class="input-group">
                    <div class="custom-file">
                        <input @change="selectFile" type="file" class="custom-file-input">
                        <label class="custom-file-label">{{ data.selectedFileName || 'Выбрать файл' }}</label>
                    </div>
                    <div class="input-group-append">
                        <button class="btn btn-primary"
                                @click="upload"
                                :disabled="!data.selectedFileName"
                                type="button">
                            Загрузить
                        </button>
                    </div>
                </div>
                <p class="text-center p-4">Поддерживаемые расширения: json, js</p>
            </div>
        </div>
    </div>
</template>

<script>

import {reactive} from "vue";
import StoreClass from "@/classes/Store";
import LocalStorageDriver from "@/classes/LocalStorageDriver";

const Store = new StoreClass(new LocalStorageDriver());

export default {
    setup() {

        const data = reactive({
            selectedFileName: null,
            selectedFileContent: null,
            selectedFileType: null,
        });

        const selectFile = async (e) => {
            const file = e.target.files[0];

            if ((file.type === "application/json" || file.type === "text/javascript")  && file.size < 1000000) {
                data.selectedFileName = file.name;
                data.selectedFileContent = await file.text();
                data.selectedFileType = file.type;
            } else {
                alert("Неверный формат файла, или файл слишком большой!")
            }
        }

        const upload = async () => {

            const project = JSON.parse(data.selectedFileType === "text/javascript"
                ? data.selectedFileContent.replace("module.exports = ", "")
                : data.selectedFileContent);

            await Store.push({
                date: (new Date()).toISOString().replace("T", " ").split('.')[0],
                name: data.selectedFileName.slice(0, 15),
                data: project
            })

            data.selectedFileName = null;
            data.selectedFileContent = null;
            data.selectedFileType = null;

            alert("Проект добавлен!");
        }

        return {
            data,
            upload,
            selectFile
        }
    }
}
</script>
